import parseInt from 'lodash/parseInt';
import { useCallback, useState } from 'react';
import { useInterval } from 'react-use';
import { perfGetSummary } from '~/modules/SDK/Perf/perfGetSummary';
import { createStore } from '~/store/createStore';
import dayAPI from '~/utils/dayAPI';
const findSessionItems = (query) => {
    let i;
    const results = [];
    for (i in sessionStorage) {
        if (i.match(query) || (!query && typeof i === 'string')) {
            const value = sessionStorage.getItem(i);
            results.push({ key: i, val: value });
        }
    }
    return results;
};
const deleteSessionItems = (query) => {
    let i;
    for (i in sessionStorage) {
        if (i.match(query) || (!query && typeof i === 'string')) {
            const value = sessionStorage.removeItem(i);
        }
    }
};
/** @deprecated 已遷移到 perf2Store 繼續開發 */
export const usePerfStore_deprecated = createStore((set, get) => {
    let tradesRef = {};
    return {
        trades: tradesRef,
        inputValueCleanWhen(data, checkFn) {
            if (!checkFn(get().inputValue__)) {
                return;
            }
            set(state => {
                state.inputValue__ = { ...data };
            });
            tradesRef = {};
            this.tradesClean();
        },
        inputValue__: {},
        lastModified: 0,
        useSummaryGet() {
            const amountInitial = get().amountInitial;
            const [data, dataSet] = useState();
            const summaryGet = useCallback(() => {
                const summary = {
                    ...perfGetSummary(amountInitial, get().trades),
                    trades: get().trades,
                };
                set(state => {
                    state.summary = summary;
                });
                return summary;
            }, [amountInitial]);
            useInterval(() => {
                dataSet(summaryGet());
            }, 3000);
            return data;
        },
        summary: {
            trades: {},
            profitFactor: 0,
            profitNet: 0,
            profitWinPerTrade: 0,
            profitLossPerTrade: 0,
            tradesSize: 0,
            winRate: 0,
            winTradeCount: 0,
            profitTotalTrade: 0,
            maxWinTrade: 0,
            maxLossTrade: 0,
            riskRatio: 0,
            startDate: '',
            endDate: '',
            profitSumDataArray: [],
            profitDataArray: [],
            profitDetailDataArray: [],
            maxDrawdown: 0,
            lossTradeCount: 0,
            tradeRatio: 0,
        },
        amountInitial: 500000,
        useTradesUpdateInterval(interval = 500) {
            useInterval(() => {
                const lastModified = get().lastModified;
                const tvLastModified = parseInt(sessionStorage.getItem('currentTVChartLastModified') || '0');
                const data_key = sessionStorage.getItem('currentTVChartSymbol') +
                    '_' +
                    sessionStorage.getItem('currentTVChartResolution');
                console.log(data_key);
                if (tvLastModified <= lastModified) {
                    deleteSessionItems(data_key);
                    return;
                }
                const allMatchedTrade = findSessionItems(data_key);
                const tradeObj = {};
                console.log(allMatchedTrade.length);
                for (const element of allMatchedTrade) {
                    const arr = element.val?.split('_');
                    if (arr?.[0]) {
                        const key = dayAPI(parseInt(arr[0])).format();
                        tradeObj[key] = {
                            datetime: dayAPI(parseInt(arr[0])),
                            qty: parseInt(arr[1]),
                            priceAt: arr[2],
                            profit: parseInt(arr[3]),
                        };
                    }
                }
                console.log(tradeObj);
                set(state => {
                    state.trades = { ...tradeObj };
                    state.lastModified = tvLastModified;
                });
            }, interval);
        },
        tradeAdd(trade) {
            tradesRef[trade.datetime.format()] = trade;
        },
        tradesClean() {
            set(state => {
                state.trades = {};
            });
        },
    };
});
/** 建立一個獨立的「績效 Store」 */
// export const createPerfStore = () => {
//   const usePerfStore = createStore<PerfStoreState>((set, get) => {
//     return {
//       trades: {},
//       inputValueCleanWhen(data, checkFn) {
//         if (!checkFn(get().inputValue__)) {
//           return
//         }
//         set(state => {
//           state.inputValue__ = { ...data }
//         })
//         this.tradesClean()
//       },
//       inputValue__: {},
//       useSummaryGet() {
//         const [data, dataSet] = useState<PerfSummary>()
//         const summaryGet = () => {
//           const trades = get().trades
//           const tradesSize = size(trades)
//           const winTrades = filter(trades, datum => datum.profit > 0)
//           const profitsTotal = sum(map(trades, datum => datum.profit))
//           return {
//             profits: profitsTotal,
//             winRate: (winTrades.length / (tradesSize || 1)) * 100,
//             tradesSize: tradesSize,
//           }
//         }
//         useInterval(() => {
//           dataSet(summaryGet())
//         }, 1000)
//         return data
//       },
//       summary: {
//         profits: 0,
//         tradesSize: 0,
//         winRate: 0,
//       },
//       tradeAdd(trade) {
//         set(state => {
//           state.trades[trade.datetime.format()] = trade
//         })
//       },
//       tradesClean() {
//         set(state => {
//           state.trades = {}
//         })
//       },
//     }
//   })
//   const { Provider, useStore, useStoreApi } = createContext<PerfStoreState>()
//   return {
//     Provider: memo(function StoreProvider(props) {
//       return <Provider initialStore={usePerfStore}>{props.children}</Provider>
//     }),
//     useStore,
//     useStoreApi,
//   }
// }
