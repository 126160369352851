import { css } from '@emotion/react';
import map from 'lodash/map';
import { memo, useMemo } from 'react';
import { toPercentage } from '~/utils/toPercentage';
import { Paper } from '@mui/material';
import { BarChart, Bar, AreaChart, Area, RadarChart, Radar, Cell, Brush, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis, PolarGrid, PolarAngleAxis, PolarRadiusAxis, CartesianGrid, } from 'recharts';
import { usePerfStore_deprecated } from '~/modules/SDK/Perf/usePerfStore';
import { perfGetMaxDrawDown } from '~/modules/SDK/Perf/perfGetMaxDrawDown';
import { flex, jc } from '~/modules/AppLayout/FlexGridCss';
import { sortBy } from 'lodash';
import dayAPI from '~/utils/dayAPI';
import { filter } from 'lodash';
/** 累積績效圖 */
export const PerfDataCharts = memo(function PerfDataCharts(props) {
    const summary_ = usePerfStore_deprecated(state => state.summary);
    const summariesTableData = useMemo(() => [...map(summary_?.profitSumDataArray || {})], [summary_?.profitSumDataArray]);
    /** 依照日期排列並保留平倉資訊 */
    const data = sortBy(summariesTableData, datum => datum.datetime).filter(datum => datum.profit !== 0);
    const gradientOffset = () => {
        const dataMax = Math.max(...summariesTableData.map(i => i.profit));
        const dataMin = Math.min(...summariesTableData.map(i => i.profit));
        if (dataMax <= 0) {
            return 0;
        }
        if (dataMin >= 0) {
            return 1;
        }
        return dataMax / (dataMax - dataMin);
    };
    const off = gradientOffset();
    return (<div css={css `
        ${flex.h.default};
        height: 100%;
        ${jc.spaceAround};

        & > div {
          width: 100%;
        }
      `}>
      <ResponsiveContainer width='100%' height='100%'>
        <AreaChart data={data}>
          <XAxis dataKey={'datetime'} tickFormatter={timeStr => timeStr.substring(10, 0)} tick={{ fontSize: 15 }}/>
          <YAxis tick={{ fontSize: 15 }}/>
          <Tooltip labelFormatter={t => 'date : ' + t.toLocaleString().substring(10, 0)}/>
          <ReferenceLine y={0} label='' stroke='#222222' strokeDasharray='2 2'/>
          <CartesianGrid vertical={false} strokeDasharray='2 2'/>
          <defs>
            <linearGradient id='splitColor' x1='0' y1='0' x2='0' y2='1'>
              <stop offset={off} stopColor='#cc2222' stopOpacity={1}/>
              <stop offset={off} stopColor='#11aa11' stopOpacity={1}/>
            </linearGradient>
          </defs>
          <Area type='linear' dataKey='profit' stroke='url(#splitColor)' fillOpacity={0.3} fill='url(#splitColor)'/>
        </AreaChart>
      </ResponsiveContainer>
    </div>);
});
/** 柱狀單筆損意圖 */
export const PerfDataBar = memo(function PerfDataBar(props) {
    const summary_ = usePerfStore_deprecated(state => state.summary);
    const summariesTableData = useMemo(() => [...map(summary_?.profitDataArray || {})], [summary_?.profitDataArray]);
    /** 依照日期排列並保留平倉資訊 */
    const data = sortBy(summariesTableData, datum => datum.datetime).filter(datum => datum.profit !== 0);
    const checkData = useMemo(() => [...map(summary_?.trades || {})], [summary_?.trades]);
    //console.log(sortBy(checkData, datum => datum.datetime))
    console.log('!!! 🍏 perfState.status.trades', summary_?.trades &&
        sortBy(filter(summary_?.trades, item => dayAPI(item.datetime).isAfter(dayAPI('2021-07-31'))), item => dayAPI(item.datetime).toDate().getTime()));
    const brush = props.hasBrush ?? true;
    return (<div css={css `
        ${flex.h.default};
        height: 100%;
        ${jc.spaceAround};
        & > div {
          width: 100%;
        }
      `}>
      <ResponsiveContainer width='100%' height='100%' minHeight={undefined} minWidth={undefined}>
        <BarChart data={data}>
          <XAxis dataKey={'datetime'} tickFormatter={timeStr => timeStr.substring(10, 0)} tick={{ fontSize: 15 }}/>
          <YAxis tick={{ fontSize: 15 }}/>
          <Tooltip labelFormatter={t => 'date : ' + t.toLocaleString().substring(10, 0)}/>
          <CartesianGrid vertical={false} strokeDasharray='2 2'/>
          <Bar dataKey='profit'>
            {data.map((datum, index) => (<Cell key={index} fill={datum.profit <= 0 ? '#11aa11' : '#cc2222'}/>))}
          </Bar>
          <ReferenceLine y={0} stroke='#aaaaaa'/>
          {brush === true && (<Brush dataKey='name' height={20} stroke='#aaaaaa'/>)}
        </BarChart>
      </ResponsiveContainer>
    </div>);
});
/** 雷達圖 */
export const PerfRadar = memo(function PerfRadar(props) {
    const trades = usePerfStore_deprecated(state => state.summary?.trades);
    const winRate = usePerfStore_deprecated(state => state.summary?.winRate ?? 0);
    const profitWinPerTrade = usePerfStore_deprecated(state => state.summary?.profitWinPerTrade ?? 0);
    const profitLossPerTrade = usePerfStore_deprecated(state => state.summary?.profitLossPerTrade ?? 0);
    const profitFactor = usePerfStore_deprecated(state => state.summary?.profitFactor ?? 0);
    const summariesTableData = useMemo(() => [...map(trades || {})], [trades]);
    const details = useMemo(() => {
        const winPerTrade = profitWinPerTrade;
        const lossPerTrade = profitLossPerTrade;
        const pfRatio = profitFactor;
        const detailObj = {
            winrate: {
                label: '勝率(%)',
                header: '勝率（獲利交易次數 / 總交易次數）',
                value: toPercentage(winRate, 100),
                adjustValue: toPercentage(winRate, 100),
                isPercentage: true,
            },
            // 0-3為範圍
            winLossTotalRatio: {
                label: '獲利因子',
                header: '獲利因子（毛利／毛損）',
                value: pfRatio.toFixed(2),
                adjustValue: toPercentage(pfRatio, 3),
            },
            // 0-5為範圍
            winLossAverageRatio: {
                label: '賺陪比(0~5)',
                header: '賺陪比',
                value: `${winPerTrade.toFixed(0)} / ${Math.abs(lossPerTrade).toFixed(0)}`,
                adjustValue: toPercentage(winPerTrade / Math.abs(lossPerTrade), 5),
            },
            mdd: {
                label: '最大回撤比例(%)',
                header: '最大回撤比例（MDD/建議資金）',
                value: toPercentage(Math.abs(perfGetMaxDrawDown((summariesTableData ?? []).map(datum => datum.profit))), 500000),
                adjustValue: 100 -
                    toPercentage(Math.abs(perfGetMaxDrawDown((summariesTableData ?? []).map(datum => datum.profit))), 500000),
                isPercentage: true,
            },
            // risk: {
            //   title: '風險指標',
            //   value: toPercentage(winTotal / winTimes, lossTotal / lossTimes),
            // },
        };
        return Object.entries(detailObj).map(([k, v], index) => ({
            key: k,
            label: v.label,
            header: v.header,
            value: v.value,
            adjustValue: v.adjustValue,
            isPercentage: v.isPercentage,
        }));
    }, [profitFactor, profitLossPerTrade, profitWinPerTrade, summariesTableData, winRate]);
    return (<div css={css `
        ${flex.h.allCenter};
        height: 100%;
        width: 50%;
      `}>
      <RadarChart cx={300 / 2} cy={290 / 2} outerRadius={(300 / 8) * 3} width={300} height={290} data={details}>
        <PolarGrid />
        <Tooltip content={<CustomizedTooltip />}/>

        <PolarAngleAxis dataKey='label' tick={(p) => CustomizedAngleLabel(p)}/>
        <PolarRadiusAxis domain={[0, 100]} angle={90} tick={(p) => CustomizedRadiusLabel(p)}/>

        <Radar dataKey='adjustValue' stroke={'#a0cde2'} fill={'#a0cde2'} fillOpacity={0.7}/>
      </RadarChart>
    </div>);
});
const CustomizedTooltip = ({ payload }) => {
    // 只會顯示有合理化在圖上的欄位，所以拿tradeValueKey比對去拿Datum物件
    if (!payload || (payload && payload.length < 1))
        return null;
    const datum = payload[0].payload;
    const adjustSubject = datum.label.split(',').slice(0, 2).join('');
    return (<Paper css={css `
        background: ${'#ffffff'};
        padding: 8px;
        border-radius: 8px;
        border: ${'#555555'} 1px solid;
        & > p {
          margin: 4px 0;
        }
      `}>
      <p>{adjustSubject}</p>
      <p>{datum.value}</p>
      {/* <p>校正百分比：{datum.adjustValue}</p> */}
    </Paper>);
};
// 每種指標名稱label
const CustomizedAngleLabel = (tickProps) => {
    const { x, y, payload } = tickProps;
    const adjustLabel = payload.value.replace('/', '');
    const lines = adjustLabel.split(',');
    const adjustX = x - 24;
    return (<g>
      <text fontSize={12} y={y} x={adjustX} textAnchor='start' fill={'#222222'}>
        {lines[0]}
      </text>
      {lines[1] && (<text fontSize={12} y={y + 14} x={adjustX} textAnchor='start' fill={'#222222'}>
          /{lines[1]}
        </text>)}
      {lines[2] && (<text fontSize={12} y={y + 28} x={adjustX} textAnchor='start' fill={'#222222'}>
          {lines[2]}
        </text>)}
    </g>);
};
// 值域
// return empty radius label, since units are displayed case by case.
const CustomizedRadiusLabel = (tickProps) => {
    const { x, y, payload } = tickProps;
    return (<g>
      <text fontSize={12} y={x} x={y} textAnchor='end' fill={'#000000'}>
        {/* {payload.value}% */}
      </text>
    </g>);
};
