import dayjs from 'dayjs';
import { reduce } from 'lodash';
import filter from 'lodash/filter';
import head from 'lodash/head';
import last from 'lodash/last';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import sum from 'lodash/sum';
import { perfGetMaxDrawDown } from '~/modules/SDK/Perf/perfGetMaxDrawDown';
import dayAPI from '~/utils/dayAPI';
let sumProfitData = [];
let profitData = [];
export const perfGetSummary = (amountInitial, deals) => {
    /** 照時間序排列 */
    const trades = sortBy(map(deals, item => ({ ...item })), (left, right) => dayAPI(left.datetime).toDate().getTime());
    /** 單筆交易損益 */
    const profitArray = map(trades, datum => datum.profit);
    /** 單筆交易日期 */
    const dateArray = map(trades, datum => dayjs(datum.datetime).format('YYYY-MM-DD HH:mm'));
    /** 單筆交易 */
    const qtyArray = map(trades, datum => datum.qty);
    /** 損益累加 */
    let sumProfit = 0;
    const profitSumArray = map(trades, datum => (sumProfit += datum.profit));
    /** 累加損益陣列 */
    sumProfitData = reduce(dateArray, (prev, value, index) => {
        prev[index] = {
            datetime: dateArray[index],
            profit: profitSumArray[index],
            qty: qtyArray[index] || 0,
        };
        return prev;
    }, []);
    /** 單筆損益陣列 */
    profitData = reduce(dateArray, (prev, value, index) => {
        prev[index] = {
            datetime: dateArray[index],
            profit: profitArray[index],
            qty: qtyArray[index] || 0,
        };
        return prev;
    }, []);
    /** 策略起始日 */
    const startDate = dayjs(head(dateArray)).format('YYYY/MM/DD');
    /** 策略最新日 */
    const endDate = dayjs(last(dateArray)).format('YYYY/MM/DD');
    /** 交易次數 */
    const tradesSize = filter(trades, datum => datum.profit !== 0).length;
    /** 交易獲利次數 */
    const profitTrades = filter(trades, datum => datum.profit > 0);
    /** 交易虧損次數 */
    const profitLossTrades = filter(trades, datum => datum.profit < 0);
    /** 交易獲利加總 */
    const profitWinTotal = sum(map(trades, datum => (datum.profit > 0 ? datum.profit : 0)));
    /** 交易虧損加總 */
    const profitLossTotal = sum(map(trades, datum => (datum.profit < 0 ? datum.profit : 0)));
    /** 交易淨損益 */
    const profitNet = profitWinTotal + profitLossTotal;
    /** 獲利因子 */
    const pf = 1 + profitNet / (amountInitial + profitNet);
    /** 單次交易最大獲利 */
    const maxWin = Math.max(...(profitArray ?? 0));
    // const maxWin = max(profitArray) ?? 0
    /** 單次交易最大虧損 */
    const maxLoss = Math.min(...(profitArray ?? 0));
    // const maxLoss = min(profitArray) ?? 0
    /** 風險報酬比 */
    const riskRatio = Math.abs(profitNet / (maxLoss || profitNet));
    /** 最大回撤 */
    const maxDrawdown = Math.abs(perfGetMaxDrawDown(profitArray));
    /** 累加損益陣列資料 for reChart */
    const profitSumDataArray = sumProfitData;
    /** 單筆損益陣列資料 for reChart */
    const profitDataArray = profitData;
    console.log({
        原始: trades.length,
        平倉次數: tradesSize,
        獲利次數: profitTrades.length,
        虧損次數: profitLossTrades.length,
    });
    return {
        trades: deals,
        profitFactor: pf,
        profitNet: profitNet,
        profitWinPerTrade: profitWinTotal / (profitTrades.length || 1),
        profitLossPerTrade: profitLossTotal / (profitLossTrades.length || 1),
        winRate: (profitTrades.length / (tradesSize || 1)) * 100,
        tradesSize: tradesSize,
        winTradeCount: profitTrades.length,
        profitTotalTrade: profitWinTotal + profitLossTotal,
        maxWinTrade: maxWin,
        maxLossTrade: maxLoss,
        riskRatio: riskRatio,
        startDate: startDate,
        endDate: endDate,
        profitSumDataArray: profitSumDataArray,
        profitDataArray: profitDataArray,
        profitDetailDataArray: [],
        maxDrawdown: maxDrawdown,
        lossTradeCount: profitLossTrades.length,
        tradeRatio: profitWinTotal / profitTrades.length / -(profitLossTotal / profitLossTrades.length),
    };
};
